import { socialLinksMap } from '@brand/config/social-links'
import styles from './footer-social-links.module.css'

export function FooterSocialLinks() {
  return (
    <ul data-tid="social-links" className={styles.footerSocialLinks}>
      {socialLinksMap.map((link, index) => {
        const Icon = link.icon
        return (
          <li
            key={`social-link-${index}`}
            className={styles.footerSocialLinkWrapper}
          >
            <a
              data-tag_item={link.tag}
              className={styles.footerSocialLinks}
              href={link.href}
              rel="external noopener noreferrer"
              target="_blank"
              title={link.alt}
            >
              <Icon aria-label={link.tag} className={styles.socialIcon} />
            </a>
          </li>
        )
      })}
    </ul>
  )
}
