'use client'
import { ReactComponent as ChevronDownIcon } from '@brand/icons/chevron-down.svg'
import { ReactComponent as ChevronUpIcon } from '@brand/icons/chevron-up.svg'
import clsx from 'clsx'
import type { ComponentProps } from 'react'
import { useState } from 'react'
import { Anchor } from '../../../../components/anchor/anchor'
import { Heading } from '../../../../components/heading/heading'
import type { TestIdProp } from '../../../../types'
import styles from './footer-link-section.module.css'

export type FooterLink = {
  tag: string
  text: string
  url: string
  'data-tid'?: string
}

type FooterLinkSectionProps = {
  colorScheme?: ComponentProps<typeof Anchor>['variant']
  heading: string
  'data-tid'?: string
  links: FooterLink[]
} & TestIdProp

export function FooterLinkSection({
  heading,
  links,
  'data-tid': tid,
}: FooterLinkSectionProps) {
  const [isCollapsed, toggleCollapsed] = useState(true)

  return (
    <div data-tid={tid} className={styles.linkSection}>
      <Heading level="4x" as="span">
        <button
          aria-expanded={isCollapsed}
          className={styles.collapse}
          onClick={() => toggleCollapsed(!isCollapsed)}
          type="button"
          data-tag_item="tab_toggle"
        >
          <span className={styles.headingLabel}>{heading}</span>
          {isCollapsed ? (
            <ChevronDownIcon data-tid="chevron-down" className={styles.icon} />
          ) : (
            <ChevronUpIcon data-tid="chevron-up" className={styles.icon} />
          )}
        </button>
      </Heading>

      <ul className={clsx(styles.list, isCollapsed && styles.listHidden)}>
        {links.map((link, linkIndex) => {
          return (
            <li key={`footer-link-${linkIndex}`} className={styles.listItem}>
              <Anchor
                href={link.url}
                data-tag_item={link.tag}
                data-tid={link['data-tid']}
              >
                {link.text}
              </Anchor>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
