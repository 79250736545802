import clsx from 'clsx'
import type { LightDarkVariant } from '../../types'
import { RentTermsLinks } from './rent-terms-links'
import styles from './rent-terms.module.css'

export type RentpathTermProps = {
  termsTagId?: string
  privacyPolicyTagId?: string
  className?: string
  copyright?: string
  colorScheme?: LightDarkVariant
}

export function RentTerms({
  termsTagId,
  privacyPolicyTagId,
  className,
  copyright = 'By submitting this form, you agree to our ',
  colorScheme,
}: RentpathTermProps) {
  return (
    <div className={clsx(styles.rentTerms, className)}>
      <div className={styles.rentTermsCopyRight}>{copyright}</div>
      <RentTermsLinks
        colorScheme={colorScheme}
        termsTagId={termsTagId}
        privacyPolicyTagId={privacyPolicyTagId}
      />
      .
    </div>
  )
}
