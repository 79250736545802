export const COPYRIGHT = `© ${new Date().getFullYear()} Rent Group Inc. All rights reserved.`
export const EQUAL_HOUSING_OPPORTUNITY_URL =
  'https://solutions.rent.com/legal/equal-housing-opportunity/'
export const FAIR_HOUSING_POLICY_URL =
  'https://solutions.rent.com/legal/equal-housing-opportunity/'
export const NEW_YORK_STATE_FAIR_HOUSING_NOTICE_URL =
  'https://dos.ny.gov/system/files/documents/2024/06/nys-housing-and-anti-discrimination-notice_06.2024.pdf'

export const PDP = '/detail'
export const SRP = '/search'

export const sitemapFooterLinksMock = [
  {
    tag: 'city_sitemap',
    text: ':city Apartments Sitemap',
    url: '/sitemap/:state/:city/apartments',
  },
  {
    tag: 'new-listings',
    text: 'New Listings in :city',
    url: '/sitemap/:state/:city/new-listings',
  },
  {
    tag: 'popular_listings',
    text: 'Popular Listings in :city',
    url: '/sitemap/:state/:city/popular-listings',
  },
]
