import { ReactComponent as FacebookIcon } from '../icons/facebook.svg'
import { ReactComponent as InstagramIcon } from '../icons/instagram.svg'
import { ReactComponent as PinterestIcon } from '../icons/pinterest.svg'
import { ReactComponent as TwitterIcon } from '../icons/twitter.svg'
import { ReactComponent as YoutubeIcon } from '../icons/youtube.svg'
import { ReactComponent as TiktokIcon } from '../icons/tiktok.svg'

const links = {
  facebook: 'https://www.facebook.com/rent',
  twitter: 'https://twitter.com/rentdotcom',
  pinterest: 'https://www.pinterest.com/rentdotcom/',
  youtube: 'https://www.youtube.com/rentdotcom',
  instagram: 'https://instagram.com/rent.social',
  tiktok: 'https://www.tiktok.com/@rent.tok',
}

interface Link {
  alt: string
  icon: React.FC<React.SVGProps<SVGSVGElement>>
  href: string
  tag: string
}

export const socialLinksMap: Link[] = [
  {
    alt: 'Facebook',
    href: links.facebook,
    icon: FacebookIcon,
    tag: 'facebook',
  },
  {
    alt: 'Twitter',
    href: links.twitter,
    icon: TwitterIcon,
    tag: 'twitter',
  },
  {
    alt: 'Tiktok',
    href: links.tiktok,
    icon: TiktokIcon,
    tag: 'tiktok',
  },
  {
    alt: 'Pinterest',
    href: links.pinterest,
    icon: PinterestIcon,
    tag: 'pinterest',
  },
  {
    alt: 'YouTube',
    href: links.youtube,
    icon: YoutubeIcon,
    tag: 'youtube',
  },
  {
    alt: 'Instagram',
    href: links.instagram,
    icon: InstagramIcon,
    tag: 'instagram',
  },
]
